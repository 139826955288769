import React from "react";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import css from "./header.module.scss";
import { ApolloClient, useApolloClient } from "@apollo/client";
import DownOutlined from "@ant-design/icons/DownOutlined";
import { NextRouter, useRouter } from "next/router";
import { signOut } from "helpers/authenticate";
import useQuery from "hooks/useQuery";
import { USER_CURRENT_QUERY_GQL } from "gql/userCurrentQuery.gql";
import Avatar from "components/header/Avatar";
import { UserCurrentQuery } from "__generated__/UserCurrentQuery";

export default function UserUtility() {
  const router = useRouter();
  const client = useApolloClient();
  const { loading, error, data } = useQuery<UserCurrentQuery>(USER_CURRENT_QUERY_GQL, {
    errorPolicy: "none",
  });
  if (loading || !data) return null;
  if (error) return <p>Error: ${error.message}</p>;
  const { firstName, lastName, id } = data.currentUser;
  return (
    <div className={css.userUtilityContainer} pendo-id="userUtility">
      <Avatar firstName={firstName} lastName={lastName} />
      <div>
        <Dropdown className={css.dropdown} overlay={_renderMenu(id, router, client)} trigger={["click"]}>
          <div className={css.userName}>
            {firstName} {lastName}
            &nbsp; <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

const _renderMenu = (userId: string, router: NextRouter, client: ApolloClient<unknown>) => () => {
  return (
    <Menu>
      <Menu.Item>
        <a href="/profile">Profile</a>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          signOut(client, () => {
            router.push("/auth/login");
          });
        }}
        pendo-id="logoutButton"
      >
        Log Out
      </Menu.Item>
    </Menu>
  );
};
